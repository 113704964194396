'use client'
import { useEffect } from 'react'
import { useAuth } from '@clerk/nextjs'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import {
  useLivUserContext,
  UserType,
} from 'contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient'

const { useRouter } = getLivSharedPathnamesNavigation()

function ClientSideHomePage(): React.JSX.Element {
  const router = useRouter()
  const { isLoaded, isSignedIn } = useAuth()
  const { userType } = useLivUserContext()

  useEffect(() => {
    if (!isLoaded) return

    if (isSignedIn) {
      if (!userType) router.replace('/profile-selection')
      if (userType === UserType.Agent) router.replace('/control-panel')
      if (userType === UserType.Owner) router.replace('/owners')
      if (userType === UserType.Tenant) router.replace('/my-investigations')
    }
    if (!isSignedIn) router.replace('/info')
  }, [isLoaded, isSignedIn, router, userType])

  return <></>
}

export default ClientSideHomePage
