export type NavigationOption = {
  href: string
  target?: string
}

export type NavigationElement = {
  id: string
  title: string
  option: NavigationOption
}

const pages: NavigationElement[] = [
  {
    id: 'blog',
    title: 'Blog',
    option: {
      href: 'https://help.liv.place/es/collections/4078943-blog',
      target: '_blank',
    },
  },
  {
    id: 'help',
    title: 'Help',
    option: {
      href: 'https://help.liv.place/es/collections/4078942-preguntas-frecuentes',
      target: '_blank',
    },
  },
  {
    id: 'terms-and-conditions',
    title: 'TermsAndConditions',
    option: {
      href: '/info/terminos-de-uso',
      target: '_blank',
    },
  },
  {
    id: 'products-contracts',
    title: 'Privacy',
    option: {
      href: '/info/politica-privacidad',
      target: '_blank',
    },
  },
]

export default pages
